import './Landingpage.scss';
import * as Components from "./Components";

import { faAt } from '@fortawesome/pro-light-svg-icons';
//import { Button, FormControl, FormControlLabel, FormLabel, Link, TextField, Typography } from '@material-ui/core';
import { Button, Paper, ThemeProvider, Typography, createMuiTheme, useMediaQuery, TextField, FormControl, FormControlLabel, FormLabel, Card, CardContent, CardActions } from '@material-ui/core';
import { deDE } from '@material-ui/core/locale';
import React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Route, useHistory } from 'react-router-dom';

import Background from '../../assets/images/landingpage/bg3.jpg';
import ForgotPasswordModal from '../../components/ForgotPasswordModal';
import ForgotPasswordModalx from '../../components/ForgotPasswordModalx';
import Icon from '../../components/Icon';
import RegisterComponent from '../../components/RegisterComponent';
import FacebookRegisterComponent from '../../components/FacebookRegisterComponent'; // This is the component for the COMPLETION of a Facebook login
import SignInComponent from '../../components/SignInComponent';
import { FORGOT_PASSWORD_PATH, LOGIN_PATH, REGISTER_PATH ,FORGOT_PASSWORD_PATHH} from '../../models/Paths';
import FBLoginButton from '../../services/Facebook/FBLoginButton';
import FacebookLogin from '@greatsumini/react-facebook-login'
import { FacebookLoginButton, GoogleLoginButton } from "react-social-login-buttons";

import useLoginHandler from '../../services/Facebook/useLoginHandler';
import { DefaultTheme } from '../../theme/DefaultTheme';
//import AppbarComponent from './components/AppbarComponent';
import CookiesWarningDialog from './components/CookiesWarningDialog';
import Footer from './components/Footer';
import Config from '../../config'
import { faFacebook, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { DirectInteractionActionCreator } from '../../actions/DirectInteractionActionCreator'
import AuthenticationActionCreator from '../../actions/AuthenticationActionCreator';
import { BerndLoginArts } from '../../temp/models/IBerndLoginCredentials';
import MD5 from 'md5'
const usnLsKey = btoa('usn')
const passwdLsKey = btoa('pswd')
import Logo from '../../assets/images/logos/logo-with-writing.png';
import Herz from '../../assets/images/icons/mogen.png';
import Stift from '../../assets/images/icons/stift.png';
import Lupe from '../../assets/images/icons/suche.png';
import GenderSelectButtonGroup from '../../components/GenderSelectButtonGroup';
import { GenderSearchTraits, GenderTraits } from '../../temp/models/BerndUserTraits';
//import { Transition, CSSTransition } from 'react-transition-group';
//import AnimateHeight from 'react-animate-height';
//import GenderSelectButtonGroup from '../../components/GenderSelectButtonGroup';
//import { GenderSearchTraits, GenderTraits } from '../../temp/models/BerndUserTraits';

const defaultOptions = [
    { key: GenderTraits.MALE, text: 'Männlich' },
    { key: GenderTraits.FEMALE, text: 'Weiblich' },
];

const genderSearchOptions = [
    { key: GenderSearchTraits.MALE, text: 'Männer' },
    { key: GenderSearchTraits.FEMALE, text: 'Frauen' },
];


export interface ILandingpageProps {}

export const Landingpage = React.memo((props: ILandingpageProps) => {

    const isDesktop = useMediaQuery('(min-width:900px)', { defaultMatches: true });

    const isMobile = useMediaQuery('(max-width:600px)', { defaultMatches: true });

    const history = useHistory();
    const [isFacebookModalOpen, setFacebookModalopen] = useState(false);
    const [loginResultFacebook, setloginResultFacebook] = useState({});
    //const nodeRef = useRef(null);

    const [gender, setGender] = useState(GenderTraits.MALE);
    const [searchGender, setSearchGender] = useState(GenderSearchTraits.FEMALE);

    const [numPage, setNumPage] = useState(1)
    const [signIn, toggle] = useState(false);
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState<string>("");
    const [repeatPW, setRepeatPW] = useState<string>(""); 


    const dispatch = useDispatch();

    const handleForgotPassword = useCallback(() => {
        history.push(FORGOT_PASSWORD_PATH);
    }, [history]);

    async function handleLogin(email: string, password: string, art?: BerndLoginArts, facebook_id?: string, google_id?: string) {
        const result = await dispatch(
            AuthenticationActionCreator.loginUser({ email, password, art, facebook_id, google_id })
        )
        if (result) {
        // this doesnt work with facebook login
            persistToLS(email, password)
        } else {
            removeFromLs()
        }
    }

  const handleFBLogin = async (response:object) => {
    const res = await (await DirectInteractionActionCreator.isExistingUser(response["email"], response["id"])).json()
    if (res[0].email_exists) {
        if (!res[0].facebook_id_exists) {
                // add social Media
            const res = await (await DirectInteractionActionCreator.addSocialMedia(response["email"], response["id"])).json()
            console.log(res)
        }
            // handle login
        console.log('handle login')
        await handleLogin(response["email"], MD5(response["id"]), BerndLoginArts.FACEBOOK, response["id"])
    } else {
        setloginResultFacebook(response)
        setFacebookModalopen(true)
    }
}
const backgroundGradient = `url(${Background})`;




const desktopPage1 = (
    (
        //Desktop
    <Components.Container>
      <Components.SignUpContainer signingIn={signIn}>
        <Components.Form>
          <Components.Title style={{marginBottom:20}}>Jetzt Registrieren</Components.Title>
        <FacebookLogin
      appId={Config.FACEBOOK_APP_ID}
      scope="public_profile, email"
      fields="name,email,picture"
      onSuccess={(response) => {
          // console.log('Login Success!', response)
          // console.log(typeof response)
      }}
      onFail={(error) => {
          //console.log('Login Failed!', error)
          setFacebookModalopen(false)
      }}
      onProfileSuccess={ handleFBLogin }
      render={({ onClick, logout }) => (
            <FacebookLoginButton onClick={onClick} style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Facebook registrieren</span>
            </FacebookLoginButton>
          )}/>
            <GoogleLoginButton style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Google registrieren</span>
            </GoogleLoginButton>
            <Typography>oder</Typography>
          <Components.Input type="email" placeholder="Deine Email" />
          <Components.Input type="password" placeholder="Passwort" />
          <Components.Input type="password" placeholder="Passswort wiederholen" />
          <Components.Button style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>mit Email registrieren</Components.Button>
        </Components.Form>
      </Components.SignUpContainer>
      <Components.SignInContainer signingIn={signIn}>
        <Components.Form>
          <Components.Title style={{marginBottom:20}} >Bereits Mitglied?</Components.Title>
           <FacebookLogin
      appId={Config.FACEBOOK_APP_ID}
      scope="public_profile, email"
      fields="name,email,picture"
      onSuccess={(response) => {
          // console.log('Login Success!', response)
          // console.log(typeof response)
      }}
      onFail={(error) => {
          //console.log('Login Failed!', error)
          setFacebookModalopen(false)
      }}
      onProfileSuccess={ handleFBLogin }
      render={({ onClick, logout }) => (
            <FacebookLoginButton onClick={onClick} style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Facebook einloggen</span>
            </FacebookLoginButton>
          )}/>
            <GoogleLoginButton style={{maxHeight:40}}>
                <span style={{fontWeight:400}}>Mit Google einloggen</span>
            </GoogleLoginButton>
          
            <Typography>oder</Typography>
          <Components.Input type="email" placeholder="Email" />
          <Components.Input type="password" placeholder="Password" />
          <Components.Button style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>Mit Email einloggen</Components.Button>
          <Components.Anchor onClick={handleForgotPassword}>Password vergessen?</Components.Anchor>
        </Components.Form>
      </Components.SignInContainer>
      <Components.OverlayContainer signingIn={signIn}>
        <Components.Overlay signingIn={signIn}>
          <Components.LeftOverlayPanel signingIn={signIn}>
            <Components.Title>Bereits Mitglied?</Components.Title>
            <Components.Paragraph style={{fontWeight:600, fontSize:15}}>
              Logge dich hier in dein persönliches Konto ein und verpasse keine Gelegenheit deinen Traumpartner zu finden.
            </Components.Paragraph>
            <Components.GhostButton hovervisible={!signIn} onClick={() => toggle(true)}>
              Einloggen
            </Components.GhostButton>
          </Components.LeftOverlayPanel>
          <Components.RightOverlayPanel signingIn={!signIn}>
            <Components.Title>Bist du neu hier?</Components.Title>
            <Components.Paragraph style={{fontWeight:600, fontSize:15}}>
            Auf Maluu findest du täglich neue Begegnungen. Dein Match wartet schon auf dich.
            </Components.Paragraph>
            <Components.Paragraph style={{fontWeight:600, fontSize:15}}>
              Melde dich jetzt an für spannende Flirts. 
            </Components.Paragraph>
            <Components.GhostButton hovervisible={signIn} onClick={() => toggle(false)}>
              Registrieren
            </Components.GhostButton>
          </Components.RightOverlayPanel>
        </Components.Overlay>
      </Components.OverlayContainer>
    </Components.Container>
    )
    )


const mobilePage1 = (
    <Components.MobileContainer>
      <Components.MobileSignUpContainer signingIn={signIn}>
        <Components.Form>          
          <Components.Title>Jetzt registrieren!</Components.Title>
            <FacebookLogin
                appId={Config.FACEBOOK_APP_ID}
                scope="public_profile, email"
                fields="name,email,picture"
                onSuccess={(response) => {
                    // console.log('Login Success!', response)
                    // console.log(typeof response)
                }}
                onFail={(error) => {
                    //console.log('Login Failed!', error)
                    setFacebookModalopen(false)
                }}
                onProfileSuccess={ handleFBLogin }
                render={({ onClick, logout }) => (
                      <FacebookLoginButton onClick={onClick} style={{maxHeight:45, minWidth:'280px'}}>
                          <span style={{fontWeight:400, fontSize:15}}>Mit Facebook registrieren</span>
                      </FacebookLoginButton>
                    )}/>
            <GoogleLoginButton style={{maxHeight:45, minWidth:'280px', marginTop:5}}>
                <span style={{fontWeight:400, fontSize:15}}>Mit Google registrieren</span>
            </GoogleLoginButton>
            <Typography style={{fontSize:15}}>oder</Typography>
          <Components.Input type="email" placeholder="Deine Email" style={{maxHeight:40, fontSize:15, minWidth:'280px'}}/>
          <Components.Input type="password" placeholder="Passwort" style={{maxHeight:40, fontSize:15, minWidth:'280px'}}/>
          <Components.Input type="password" placeholder="Passswort wiederholen" style={{maxHeight:40, fontSize:15, minWidth:'280px'}}/>
          <Components.Button style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>mit Email registrieren</Components.Button>
        </Components.Form>
      </Components.MobileSignUpContainer>
      <Components.MobileSignInContainer signingIn={signIn}>
        <Components.Form>
          <Components.Title>Bereits Mitglied?</Components.Title>
                     <FacebookLogin
      appId={Config.FACEBOOK_APP_ID}
      scope="public_profile, email"
      fields="name,email,picture"
      onSuccess={(response) => {
          // console.log('Login Success!', response)
          // console.log(typeof response)
      }}
      onFail={(error) => {
          //console.log('Login Failed!', error)
          setFacebookModalopen(false)
      }}
      onProfileSuccess={ handleFBLogin }
      render={({ onClick, logout }) => (
            <FacebookLoginButton onClick={onClick} style={{maxHeight:40, minWidth:'280px'}}>
                <span style={{fontWeight:400, fontSize:15}}>Mit Facebook einloggen</span>
            </FacebookLoginButton>
          )}/>
            <GoogleLoginButton style={{maxHeight:40, minWidth:'280px'}}>
                <span style={{fontWeight:400, fontSize:15}}>Mit Google einloggen</span>
            </GoogleLoginButton>
            <Typography>oder</Typography>
          <Components.Input type="email" placeholder="Email" style={{maxHeight:40, fontSize:15, minWidth:'280px'}}/>
          <Components.Input type="password" placeholder="Password" style={{maxHeight:40, fontSize:15, minWidth:'280px'}}/>
          <Components.Button style={{ marginBottom: 10, marginTop:10, minWidth:280 }}>Mit Email einloggen</Components.Button>
          <Components.Anchor href="#">Password vergessen?</Components.Anchor>
        </Components.Form>
      </Components.MobileSignInContainer>
      <Components.MobileOverlayContainer signingIn={signIn}>
        <Components.MobileOverlay signingIn={signIn}>
          <Components.MobileTopOverlayPanel signingIn={signIn}>
            <Components.Title style={{fontSize:20}}>Bereits Mitglied?</Components.Title>
            <Components.GhostButton hovervisible={!signIn} onClick={() => toggle(true)}>
              Einloggen
            </Components.GhostButton>
          </Components.MobileTopOverlayPanel>
          <Components.MobileBottomOverlayPanel signingIn={signIn}>
            <Components.Title style={{fontSize:20}}>Bist du neu hier?</Components.Title>
            <Components.GhostButton hovervisible={signIn} onClick={() => toggle(false)}>
              Registrieren
            </Components.GhostButton>
          </Components.MobileBottomOverlayPanel>
        </Components.MobileOverlay>
      </Components.MobileOverlayContainer>
    </Components.MobileContainer>
        );

const desktopPage2 = (
    <div>test</div>
    )

const mobilePage2 = (
    <div>test</div>
    )

return (
    <ThemeProvider theme={createMuiTheme(DefaultTheme, deDE)}>
    <div
    className="flex container-one"
    style={{
        backgroundImage: backgroundGradient,
        minHeight: '100vh',
        backgroundPosition: 'top',
    }}
    >
    <div
    className={`flex ${isDesktop ? 'column' : 'column'} container ${
        isDesktop ? 'justify-content-center' : 'justify-content-center'
    } align-items-center`}
    style={{ paddingTop: 30, paddingBottom: 100 }}
    >
    <div style={isDesktop ? {} : { textAlign: 'center', marginBottom: 24 }}>
    <Typography
    variant={isDesktop ? 'h3' : 'h3'}
    style={{ color: '#fff', textShadow: 'rgb(0 0 0 / 29%) 0px 2px 1px', fontWeight: 700 }}
    >
                                {/* Dating mit Niveau */}
    </Typography>
    </div>

    <div style={{ paddingTop: 0, paddingBottom: 10, justifyContent:'center', alignItems:'center', display:'flex' }}>
        {isMobile ? (<img src={Logo} style={{maxHeight:'50px'}}/>) : (<img src={Logo} style={{maxHeight:'100px'}}/>) }
    </div>

    {numPage==1 ? (isMobile ? mobilePage1 : desktopPage1) : (isMobile ? mobilePage2 : desktopPage2)}

    </div>    
    </div>    
    <div>
    <Paper className="flex row wrap" style={{justifyContent:'center'}}>
    <Typography variant="h4" style={{width:'100%', textAlign:'center', marginTop:30}}>
          Besonderheiten
    </Typography>
    <Card variant="outlined"  style={{ minWidth:'350px', width: '25%', marginLeft:5, marginRight:5, boxShadow:'2px 2px 2px 2px rgb(0 0 0 / 29%)', borderRadius:10, marginTop:40}}>
      <CardContent>
        <img src={Herz} style={{width:'100px', display:'block', margin:'auto'}}/>
        <Typography variant="h5" style={{textAlign:'center', marginBottom:10}}>
          Der ideale Begleiter
        </Typography>
        <Typography style={{textAlign:'center'}}>
          Hier kannst du jemanden finden, der deine Werte, Interessen und Lebensziele teilt und sowohl ein romantischer Partner als auch ein vertrauensvoller Freund fürs Leben sein kann.
        </Typography>
      </CardContent>
    </Card>
    <Card variant="outlined" style={{ minWidth:'350px', width: '25%', marginLeft:5, marginRight:5, boxShadow:'2px 2px 2px 2px rgb(0 0 0 / 29%)', borderRadius:10, marginTop:40}}>
      <CardContent>
        <img src={Stift} style={{width:'100px', display:'block', margin:'auto'}}/>
        <Typography variant="h5" style={{textAlign:'center', marginBottom:10}}>
          Raum für Neues
        </Typography>
        <Typography style={{textAlign:'center'}}>
          Hier findest du den idealen Ort, um jemanden kennenzulernen, der zu dir passt – egal wo du dich befindest. Es geht darum, den passenden Standort für eine besondere Verbindung zu schaffen, die in deinem Leben Raum findet.
        </Typography>
      </CardContent>
    </Card>
        <Card variant="outlined" style={{ minWidth:'350px', width: '25%', marginLeft:5, marginRight:5, boxShadow:'2px 2px 2px 2px rgb(0 0 0 / 29%)', borderRadius:10, marginTop:40}}>
      <CardContent>
        <img src={Lupe} style={{width:'100px', display:'block', margin:'auto'}}/>
        <Typography variant="h5" style={{textAlign:'center', marginBottom:10}}>
          Entdecke unsere Mitglieder
        </Typography>
        <Typography style={{textAlign:'center'}}>
          Erkunde unsere Mitglieder und entdecke Menschen, die deine Interessen und Werte teilen und vielleicht die perfekte Ergänzung in deinem Leben sein könnten.
        </Typography>
      </CardContent>
    </Card>
    </Paper>
        <Footer />
        <Route path={LOGIN_PATH} component={SignInComponent} />
        <Route path={FORGOT_PASSWORD_PATH} component={ForgotPasswordModal} />
        <Route path={FORGOT_PASSWORD_PATHH} component={ForgotPasswordModalx} />
        <Route path={REGISTER_PATH} component={RegisterComponent} />
    </div>
    <CookiesWarningDialog />
    </ThemeProvider>
    );
});

function persistToLS(email: string, password: string) {
    localStorage.setItem(usnLsKey, btoa(email));
    localStorage.setItem(passwdLsKey, btoa(password));
}

function removeFromLs() {
    localStorage.removeItem(usnLsKey);
    localStorage.removeItem(passwdLsKey);
}

function retrieveFromLS() {
    const email = localStorage.getItem(usnLsKey) ?? '';
    const password = localStorage.getItem(passwdLsKey) ?? '';

    return {
        email: atob(email),
        password: atob(password),
    };
}
